import styled from 'styled-components';

export const HeaderStyles = styled.header`
  position: sticky;
  top: 0;
  z-index: 3;
  
  padding: 20px 0;
  
  @media (min-width: 768px) {
    padding: 40px 0;
  }
  
  transition: .3s ease background-color;
  
  &.greenBg {
    background-color: #439000;
  }
  
  &.orangeBG {
    background-color: #FF7300;
  }
  
  &.open:not(.greenBg) {
    background-color: #FF7300;
  }
  
  &:not(.open, .orangeBG) {
    &::after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100dvw;
      height: 100%;
      background-color: #FFF;
      opacity: 0.6;
    }
    &.greenBg {
      &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100dvw;
        height: 100%;
        background-color: #439000;
        opacity: 0.6;
      }
    }
  }
  
  .container {
    position: relative;
    z-index: 1;
    
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    button, a {
      font-size: 20px;
      font-weight: 900;
      text-transform: uppercase;
      color: #0C0C0C;
      
      @media (min-width: 768px) {
        font-size: 22px;
      }
    }
    
    .rightSide {
      .menuHandler {
        display: flex;
        align-items: center;
        gap: 4px;
        
        .minus {
          transform: translateY(-3px);
        }
      }
    }
    
    .leftSide {
      display: flex;
      align-items: center;
      gap: 20px;
      
      @media (min-width: 768px) {
        gap: 50px;
      }
      
      .languagesHandler {
        display: flex;
        gap: 6px;
        
        @media (min-width: 768px) {
          gap: 10px;
        }
        
        button {
          opacity: 0.4;
          transition: 0.3s ease opacity;

          &:hover:not(.active) {
            opacity: 0.7;
          }
          
          &.active {
            opacity: 1;
          }
        }
      }
    }
  }
`;
