import React, { Fragment, useEffect } from 'react';
import './App.css';
import {
  Navigate, Route, Routes, useLocation, useNavigate,
} from 'react-router-dom';
import Main from './pages/main/Main';
import Layout from './components/layout/Layout';
import { useTranslation } from 'react-i18next';
import { availableLanguages } from './i18n/availableLanguages';
import Cybulskaya from './pages/cases/cybulskaya/Cybulskaya';
import UFNPA1 from './pages/cases/ufnpa1/UFNPA1';
import JerryHeilZdn from './pages/cases/jerryheilzdn/Jerryheilzdn';
import Mykolaichuk from './pages/cases/mykolaichuk/Mykolaichuk';
import MountainBreeze from './pages/cases/mountainBreeze/MountainBreeze';
import Divergent from './pages/cases/divergent/Divergent';
import UNFPA3 from './pages/cases/unfpa3/UNFPA3';
import UNFPA2 from './pages/cases/unfpa2/UNFPA2';
import Cases from './pages/cases/Cases';
import Form from './pages/form/Form';
import { Helmet } from 'react-helmet-async';
import Policy from './pages/policy/Policy';
import Unilever from './pages/cases/unilever/Unilever';
import UNFPA4 from './pages/cases/unfpa4/UNFPA4';
import MinistryOfVeteransAffairs from './pages/cases/ministryOfVeteransAffairs/MinistryOfVeteransAffairs';
import BlessedEarth from './pages/cases/blessedEarth/BlessedEarth';
import UNFPA5 from './pages/cases/unfpa5/UNFPA5';
import Coffee from './pages/cases/coffee/Coffee';
import Znay from './pages/cases/znay/Znay';
import Divergent2 from './pages/cases/divergent-2/Divergent-2';
import Reporting from './pages/reporting/Reporting';

let prevLanguage: string = '';

function App() {
  const { i18n, t } = useTranslation('meta_data');

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    prevLanguage = i18n.language;
    if (location.search.length) {
      const goto = new URLSearchParams(location.search).get('goto');
      if (goto) {
        navigate(goto);
      }
    }
  }, []);

  useEffect(() => {
    if (prevLanguage === i18n.language) {
      window.scroll(0, 0);
    }

    prevLanguage = i18n.language;
  }, [location]);

  useEffect(() => {
    const splitLocation = location.pathname.split('/');

    if (splitLocation[1] !== i18n.language) {
      splitLocation[1] = i18n.language;

      navigate(splitLocation.join('/'));
    }
  }, [i18n.language]);

  return (
    <>
      <Helmet>
        <title>{t('title')}</title>
        <meta name="description" content={t('description')} />
        <meta name="og:title" content={t('title')} />
        <meta name="og:description" content={t('description')} />
        <meta name="lang" content={i18n.language} />
        <html lang={i18n.language} />
      </Helmet>

      <Routes>
        {availableLanguages.map((language) => (
          <Fragment key={language}>
            <Route path={`/${language}/form`} element={<Form />} />

            <Route key={language} element={<Layout />} path={language}>
              <Route path="" element={<Main />} />

              <Route path="cases" element={<Cases />} />
              <Route path="cases/prosecco" element={<Cybulskaya />} />
              <Route path="cases/unfpa2022" element={<UFNPA1 />} />
              <Route path="cases/jerryheilzdn" element={<JerryHeilZdn />} />
              <Route path="cases/mykolaichuk" element={<Mykolaichuk />} />
              <Route path="cases/utonu" element={<MountainBreeze />} />
              <Route path="cases/morphine13" element={<Divergent />} />
              <Route path="cases/unfpa" element={<UNFPA3 />} />
              <Route path="cases/unfpa-4" element={<UNFPA4 />} />
              <Route path="cases/unfpa-5" element={<UNFPA5 />} />
              <Route path="cases/unilever" element={<Unilever />} />
              <Route path="cases/ministry-of-veterans-affairs-of-ukraine" element={<MinistryOfVeteransAffairs />} />
              <Route path="cases/blessed-earth" element={<BlessedEarth />} />
              <Route path="cases/you-ve-made-me-coffee" element={<Coffee />} />
              <Route path="cases/znay" element={<Znay />} />
              <Route path="cases/divergent" element={<Divergent2 />} />

              <Route path="cases/richutim" element={<UNFPA2 />} />
              <Route path="policy" element={<Policy />} />
              <Route path="reporting" element={<Reporting />} />
            </Route>
          </Fragment>
        ))}

        <Route path="*" element={<Navigate to={`/${i18n.language}`} />} />
      </Routes>
    </>
  );
}

export default App;
