import ReportingStyles from './ReportingStyles';
import bannerImage from '../../assets/images/main/divergent-banner.jpg';
import React from 'react';
import FileIcon from '../../assets/icons/reporting/FileIcon';

import { useTranslation } from 'react-i18next';

function Reporting() {
  const { t } = useTranslation('reporting');

  return (
    <ReportingStyles>
      <div className="container">
        <img src={bannerImage} alt="DIVERGENT" />

        <p className="">
          {t('description_1')}
          <br />
          <br />
          {t('description_2')}
        </p>

        <a href="https://drive.google.com/file/d/1JWkHmsKSbASmtdLZxxjIivDZ-aPO75Fi/view?usp=drive_link" target="_blank" rel="noopener noreferrer">
          <FileIcon />
          {' '}
          {t('link_text')}
        </a>
      </div>
    </ReportingStyles>
  );
}

export default Reporting;
