import styled from 'styled-components';

export const FooterStyles = styled.footer`
  padding: 20px 0;

  @media (min-width: 768px) {
    padding: 30px 0;
  }

  background: #000;
  color: #FFF;

  .content {
    .brandNameContainer {
      margin-bottom: 60px;

      text-align: center;

      @media (min-width: 768px) {
        margin-bottom: 78px;
      }

      h4 {
        font-size: 14px;
        font-weight: 900;
      }
    }

    .formWrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 30px;

      margin-bottom: 30px;

      @media (min-width: 768px) {
        margin-bottom: 80px;
      }

      .formTitle {
        font-size: 18px;
        font-weight: 900;
        text-transform: uppercase;

        margin: auto;
        text-align: center;

        max-width: 317px;

        @media (min-width: 768px) {
          max-width: 550px;
        }
      }

      form {
        max-width: 622px;
        width: 100%;
      }

      label {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 20px;
        border: 2px solid rgba(255, 255, 255, 0.15);
        padding: 24px;
        width: 100%;
        box-sizing: border-box;
        cursor: pointer;
        transition: 0.3s ease border;

        &:hover {
          border: 2px solid rgba(255, 255, 255, 0.25);
        }

        .submitButton {
          color: #FFF;

          font-size: 18px;
          font-weight: 900;
          text-transform: uppercase;

          transition: 0.3s ease opacity;
          opacity: 0.8;

          &:disabled {
            opacity: 0.2;
          }
        }

        input {
          width: 100%;
          background: inherit;
          border: none;
          outline: none;
          color: #FFF;
          font-family: Helvetica Neue;
          font-size: 18px;
          font-style: normal;
          font-weight: 900;

          &::placeholder {
            color: #FFF;
            
            opacity: 0.5;

            font-family: Helvetica Neue;
            font-size: 18px;
            font-style: normal;
            font-weight: 900;
          }
        }
      }
    }
    
    .link {
      position: relative;
      
      color: #FFF;

      font-size: 17px;
      font-weight: 900;
      line-height: 19px;


      &::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: -2px;
        background-color: #fff;
        height: 1px;
        width: 0;
        transition: .3s ease width;
      }
      
      &:hover {
        &::after {
          width: 100%;
        }
      }
    }

    .navigationAndContactsWrapper {
      display: flex;
      flex-direction: column;
      
      max-width: 400px;
      margin: auto auto 40px auto;
      
      @media (min-width: 1024px) {
        max-width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
      }

      nav {
        display: flex;
        flex-direction: column;
        gap: 8px;
        
        margin-bottom: 30px;
        
        @media (min-width: 768px) {
          margin-bottom: 0;
        }
        
        a {
          width: fit-content;
        }
      }

      .rightSide {
        @media (min-width: 1024px) {
          display: flex;
          justify-content: space-between;
          grid-column: 3/4;
        }
        
        .locationsAndContacts {
          display: flex;
          flex-direction: column;
          gap: 8px;
          
          @media (min-width: 500px) {
            flex-direction: row;
            gap: 30px;
          }
          
          @media (min-width: 1024px) {
            flex-direction: column;
            justify-content: flex-start;
            gap: 8px;
          }
        }
        
        .socialMedias {
          display: flex;
          gap: 10px;
          
          @media (min-width: 1024px) {
            gap: 4px;
            
            flex-direction: column;
          }
          
          a > svg {
            path {
              transition: 0.3s ease fill;
            }
          }

          .youtube:hover {
            svg {
              path {
                fill: #e81212;
              }
            }
          }

          .instagram:hover {
            svg {
              path {
                fill: #E4405F;
              }
            }
          }

          .facebook:hover {
            svg {
              path {
                fill: #1877F2;
              }
            }
          }
        }
      }
    }

    .bottomText {
      svg {
        width: 100%;
        height: auto;
      }
    }
  }
`;
