import styled from 'styled-components';

const ReportingStyles = styled.div`
  padding: 32px 0;

  background-color: #FF7300;

  .container {
    display: flex;
    flex-direction: column;
    gap: 32px;

    font-size: 25px;
    font-weight: 900;

    @media (min-width: 768px) {
      font-size: 35px;
    }
    
    a {
      display: flex;
      align-items: center;
      gap: 8px;
      
      svg {
        width: fit-content;
        height: 30px;
        
        @media (min-width: 768px) {
          height: 40px;
        }
      }
      
      color: #000;
    }

    img {
      border-radius: 12px;
    }
  }
`;

export default ReportingStyles;
