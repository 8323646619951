import React, { useEffect } from 'react';
import { MenuStyles } from './MenuStyles';
import classNames from 'classnames';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface Props {
  menuOpen: boolean;
  greenBg?: boolean;
  handleCloseMenu(): void;
}

function Menu({ menuOpen, greenBg, handleCloseMenu }: Props) {
  const { i18n, t } = useTranslation('layout');

  const { pathname } = useLocation();

  return (
    <MenuStyles className={classNames({ open: menuOpen, greenBg })}>
      <nav>
        <div className="linkWrapper">
          <div className="container">
            <Link to={`/${i18n.language}`} onClick={handleCloseMenu} className="black">
              {t('menu_main')}
            </Link>

            <div className={classNames('circle', { black: pathname.split('/').length !== 2 })} />
          </div>
        </div>

        <div className="linkWrapper">
          <div className="container">
            <Link to={`/${i18n.language}/cases`} onClick={handleCloseMenu} className="black">
              {t('menu_works')}
            </Link>

            <div
              className={classNames('circle', { black: !((pathname.split('/').length === 3 && pathname.includes('cases'))) })}
            />
          </div>
        </div>

        <div className="linkWrapper">
          <div className="container">
            <Link to={`/${i18n.language}/reporting`} onClick={handleCloseMenu} className="black">
              {t('menu_reporting')}
            </Link>

            <div
              className={classNames('circle', { black: !((pathname.split('/').length === 3 && pathname.includes('reporting'))) })}
            />
          </div>
        </div>

        {/* <div className="linkWrapper"> */}
        {/*  <div className="container"> */}
        {/*    <Link to={`/${i18n.language}/cases`} onClick={handleCloseMenu} className="black"> */}
        {/*      About */}
        {/*    </Link> */}

        {/*    <div className={classNames('circle black', { white: pathname.includes('cases') })} /> */}
        {/*  </div> */}
        {/* </div> */}
      </nav>

      <div className="footer container">
        <a className="emailLink" href={`mailto:${process.env.REACT_APP_EMAIL}`}>{process.env.REACT_APP_EMAIL}</a>

        <Link to={`/${i18n.language}/form`} className="fillFormLink">
          {t('menu_fill_form')}
          {' '}
          +
        </Link>
      </div>
    </MenuStyles>
  );
}

export default Menu;
