import React from 'react';
import { CasesBaseStyles } from '../CasesBaseStyles';
import Banner from '../../../components/cases/banner/Banner';
import BackStage from '../../../components/cases/backStage/BackStage';
import Results from '../../../components/cases/divergent/results/Results';
import OtherCases from '../../../components/cases/otherCases/OtherCases';
import { useTranslation } from 'react-i18next';

function Divergent() {
  const { t } = useTranslation('case_divergent');

  return (
    <CasesBaseStyles>
      <Banner
        title="Morphine 13"
        videoUrl="https://youtu.be/X_u0rO-ab_g"
        descriptionBlock={
          {
            title: t('banner_description_block_title'),
            platformName: 'Youtube',
            description: t('banner_description_block_description'),
            category: [t('banner_description_block_category')],
            link: 'https://youtu.be/X_u0rO-ab_g',
            clients: [t('banner_description_block_client')],
          }
        }
      />

      <BackStage
        title={t('backstage_title')}
        titleColor="#000"
        titleWrapperBackgroundColor="#FFF"
        videoUrl="https://youtu.be/DF-QqtAaDSE"
        descriptionBlocks={
          [
            {
              description: t('backstage_description_block_1_description'),
              title: t('backstage_description_block_1_title'),
              backgroundColor: '#000000',
            },
            {
              description: t('backstage_description_block_2_description'),
              title: t('backstage_description_block_2_title'),
              backgroundColor: '#439000',
            },
          ]
        }
      />

      <Results />
      <OtherCases />
    </CasesBaseStyles>
  );
}

export default Divergent;
