import React, { useMemo } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Header from '../header/Header';
import { LayoutStyles } from './LayoutStyles';
import Footer from '../footer/Footer';
import GoToFormSection from '../goToFormSection/GoToFormSection';

function Layout() {
  const { pathname } = useLocation();

  const isGoToFormVisible = useMemo(() => {
    if (pathname.split('/').length === 2) {
      return false;
    }

    if (pathname.includes('reporting')) {
      return false;
    }

    return true;
  }, [pathname]);

  return (
    <LayoutStyles>
      <Header />
      <main>
        <Outlet />
      </main>
      {isGoToFormVisible && <GoToFormSection />}
      <Footer />
    </LayoutStyles>
  );
}

export default Layout;
